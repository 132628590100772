.bgg {
  background: linear-gradient(to bottom, #0d6efd, #4489f1);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

h1, p {
  color: white;
}

.lgci-text {
  color: #ffdd57;
}

.input-group .form-control {
  border-radius: 0;
  border: none;
}

.input-group .btn {
  background-color: #ffdd57;
  border: none;
  border-radius: 0;
  transition: background-color 0.3s ease;
}

.input-group .btn:hover {
  background-color: #e6c22e;
}

.bg-2 {
  background: linear-gradient(to bottom, #4489f1, #0d6efd);
}

.course-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.course-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.text-course {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1em;
  color: #333;
}

.modal-content {
  border-radius: 10px;
  background-color: #f9f9f9;
}

.selected-course-p{
  color: black;
}

/* Search Bar Container */
.search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  border-radius: 50px;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Input Styling */
.search-input {
  flex-grow: 1;
  padding: 10px 20px;
  margin: 0;
  border: none;
  outline: none;
  font-size: 1rem;
  border-radius: 50px 0 0 50px;
  background-color: #f5f5f5;
  transition: background-color 0.3s ease;
}

.search-input:focus {
  background-color: #e6e6e6;
}

/* Button Styling */
.search-button {
  padding: 10px 25px;
  margin: 0;
  border: none;
  background-color: #ffdd57;
  color: #333;
  font-weight: bold;
  border-radius: 0 50px 50px 0;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.search-button:hover {
  background-color: #e6c22e;
  transform: scale(1.05);
}

.search-button:active {
  transform: scale(0.98);
}
