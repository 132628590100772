/* Base Styles */
.verify-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  transition: box-shadow 0.3s ease;
}

.verify-container:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

h1 {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
}

.form-label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

.form-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.form-button {
  width: 100%;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-button:disabled {
  background-color: #ccc;
}

.form-button:hover:not(:disabled) {
  background-color: #0056b3;
}

.error-message {
  color: red;
  margin-top: 10px;
  font-size: 0.9rem;
  text-align: center;
}

.verification-result {
  margin-top: 20px;
  padding: 15px;
  background-color: #e9f5e9;
  border: 1px solid #b5ddb5;
  border-radius: 5px;
}

.verification-result p {
  color: #3c763d;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .verify-container {
    width: 100%;
    padding: 15px;
  }

  h1 {
    font-size: 1.25rem;
  }

  .form-input, .form-button {
    font-size: 0.9rem;
    padding: 8px;
  }

  .verification-result {
    font-size: 0.9rem;
  }
}
