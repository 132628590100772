/* Container */
.stu-panel-container {
    padding: 20px;
    background-color: #f5f5f5;
    min-height: 100vh;
}

/* Sidebar */
.sidebar-stu-panel {
    background-color: #343a40;
    padding: 20px;
    border-radius: 8px;
}

.sidebar-stu-panel .nav-link {
    color: #ffffff;
    font-weight: 500;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
}

.sidebar-stu-panel .nav-link:hover {
    background-color: #495057;
    color: #ffffff;
    text-decoration: none;
}

/* Main Content */
.outlet-stu-panel {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 0;
    margin: 0;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .sidebar-stu-panel {
        margin-bottom: 20px;
    }
}