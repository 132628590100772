@import url('https://fonts.googleapis.com/css2?family=Eczar:wght@400..800&display=swap');

.sq-ratio {
    width: 10%;
    height: 10%;
  }

a{
  font-size: 20px;
}

.sitename{
  font-size: 2em;
  font-family: "Eczar", serif;
  font-optical-sizing: auto;
  letter-spacing: 1.5mm;
  font-style: normal;
  font-weight: 800;
  color: white;
  align-items: center;
}

.navItems{
  color: white;
  font-weight: 400;
  align-items: center;
  justify-content: end;
}

.imgLogo{
  border-radius: 100%;
  width: 35%;
  height: 50px;
}


.navItems .nav-link {
  color: white;
  margin-right: 10px;
}