.centerText{
    text-align: center;
    align-items: center;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s linear infinite;
    margin: 0 auto;
    align-items: center;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}
  
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh; 
}

.userCard {
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 250px;
  height: 250px;
  margin: 5px;
  padding: 0;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.userImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}