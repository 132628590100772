.about-main{
    color: black;
}

.pageContainer {
    text-align: center;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.heading {
    font-size: 36px;
    margin-bottom: 20px;
}

.instructorContainer {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.instructorCard {
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: whitesmoke;
    width: 300px;
    margin: 10px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.instructorImage {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
}

.instructorName {
    font-size: 22px;
    margin: 10px 0;
}

.instructorDescription {
    font-size: 16px;
    color: #555;
}

.instituteContainer {
    margin-top: 40px;
    text-align: left;
}

.text-color-subtitle{
    color: rgb(72, 72, 72);
}