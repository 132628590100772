.color-white{
  color: black;
}


.course_desc{
    display: -webkit-box;
    -webkit-line-clamp: 2; 
    line-clamp: 2; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: black;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s linear infinite;
    margin: 0 auto;
    align-items: center;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh; 
}

/* Model CSS */

.full-width-modal .modal-dialog {
  max-width: 100%;
  width: 100wh;
  margin: 0;
}

.full-width-modal .modal-content {
  border-radius: 1%;
}

.model-color{
  color: black;
}

.learn-more-btn{
  cursor: pointer;
  color: blue;
  border: 1px solid#09f;
  border-radius: 20px;
  text-align: center;
  padding-block: 2%;

  &&:hover{
    background-color: blue;
    color: white;
    border-color: white;
  }
}